<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <form ref="contact_information_form">
        <b-row>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t('Email')}} </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('email')"
                  rules="required|email"
              >
                <b-form-input name="email"
                    v-model="contact_information.email"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Enter email address')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t('Official mobile numbers')}} </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Official mobile numbers')"
                  rules="required"
              >
                <b-form-tags name="official_mobile_numbers[]"
                    v-model="contact_information.official_mobile_numbers"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('write official mobile number')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t('Cadet calling numbers')}} </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Cadet calling numbers')"
                  rules="required"
              >
                <b-form-tags name="cadet_calling_numbers[]"
                    v-model="contact_information.cadet_calling_numbers"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('write cadet calling numbers')"
                />
               <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t('Map location')}} </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Map location')"
                  rules="required"
              >
                <b-form-input name="map_location"
                    v-model="contact_information.map_location"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Past here embedded map location')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t('Address')}} </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Address')"
                  rules="required"
              >
                <b-form-textarea v-model="contact_information.address" name="address"
                                 :state="errors.length > 0 ? false:null"
                                 :placeholder="$t('Write Address here')">

                </b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col md="12" class="mt-2">
            <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </form>
    </validation-observer>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BRow, BCol, BButton,BCardText,BFormGroup,BFormTextarea,BFormTags
} from 'bootstrap-vue'
import {
  required,email,
} from '@validations'
import apiCall from "@/libs/axios";

export default {
  name:'ContactInformation',
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormTextarea,BFormTags,
    BRow,BFormGroup,
    BCol,
    BButton,
    BCardText,
  },
  data() {
    return {
      required,email,
    }
  },
  created(){
      this.$store.dispatch('GET_ALL_CONTACT_INFORMATION');
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.contact_information_form);
          apiCall.post('/contact/information/store',data).then((response)=>{
            if (response.data.status==='success'){
              this.$toaster.success(response.data.message);
              //this.$refs.contact_information_form.reset();
              this.$refs.simpleRules.reset();
              this.$store.dispatch('GET_ALL_CONTACT_INFORMATION');
            }else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error)=>{
            if (error.response.status ==422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            }
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
  },
  computed:{
    contact_information(){
      return this.$store.getters.contact_information;
    }
  }
}
</script>